import React, { useEffect, useState } from "react";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import "./dashboard.css";
import Header from "../../Component/Header/Header";
import Footer from "../../Component/Footer/Footer";
import logo from "../../Assets/image/bay.png";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  CircularProgress,
  Typography,
} from "@mui/material";
import { Star } from "@mui/icons-material";
import { apiCallNew } from "../../Network_Call/apiservices";
import ApiEndPoints from "../../Network_Call/ApiEndPoint";
import { formatCapitalize } from "../../Component/ReuseFormat/ReuseFormat";
import { useNavigate } from "react-router-dom";
import flowimg from "../../Assets/image/flow.jpg";
import jeanss from "../../Assets/image/jeanss.jpg";
import books from "../../Assets/image/books.jpg";
import { doller } from "../../Component/ReuseFormat/Doller";

const divStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundSize: "cover",
  height: "400px",
  borderRadius: "15px",
  margin: "20px 0px",
};

const slideImages = [
  {
    url: flowimg,
    caption: "Slide 1",
  },
  {
    url: jeanss,
    caption: "Slide 2",
  },
  {
    url: books,
    caption: "Slide 3",
  },
  {
    url: "https://i.ebayimg.com/thumbs/images/g/cjsAAOSwJRNlqAa5/s-l1200.webp",
    caption: "Slide 4",
  },
  {
    url: "https://i.ebayimg.com/00/s/MTM4OFgzODEw/z/RdYAAOSwzZtmbBpI/$_57.JPG",
    caption: "Slide 5",
  },
  {
    url: "https://i.ebayimg.com/00/s/NDk2WDE0NDA=/z/hcQAAOSwg2dj6n~7/$_57.JPG",
    caption: "Slide 6",
  },
];

const Dashboard = () => {
  const navigate = useNavigate();
  const [subCategoriesList, setSubCategoriesList] = React.useState([]);
  const [load, setload] = useState(false);
  const [dealProduct, setDealProduct] = useState([]);

  const ReverseOrder = [...subCategoriesList].reverse();
  const visibleProducts = dealProduct?.slice(0, 8);
  useEffect(() => {
    getSubCategories();
    getDailydealProduct();
  }, []);
  const getSubCategories = () => {
    try {
      setload(true);
      apiCallNew("get", {}, ApiEndPoints.SubCategoriesList + 181).then(
        (response) => {
          if (response.success) {
            setSubCategoriesList(response.result);
            setload(false);
          } else {
            setload(false);
          }
        }
      );
    } catch (error) {
      console.log(error);
      setload(false);
    }
  };

  const handleSelectSubCategory = (item) => {
    navigate(`/category/${item?.id}`, { state: { category: item } });
  };

  const getDailydealProduct = async () => {
    const payload = { page: 0, today_deal: 1, keyword: "" };
    try {
      setload(true);
      const response = await apiCallNew(
        "post",
        payload,
        ApiEndPoints.ShopProductList
      );
      if (response.success) {
        setDealProduct(response.result);
        setload(false);
      } else {
        setload(false);
      }
    } catch (error) {
      console.error("Error fetching shop products:", error);
      setload(false);
    }
  };
  return (
    <>
      <Header />
      {load && (
        <div style={styles.backdrop}>
          <CircularProgress style={styles.loader} />
        </div>
      )}
      <div className="mt-2 mb-5" style={{ padding: "0px 40px" }}>
        <div className=" slide-container">
          <Slide>
            {slideImages.map((slideImage, index) => (
              <div key={index} style={{ borderRadius: "50px" }}>
                <div
                  style={{
                    ...divStyle,
                    backgroundImage: `url(${slideImage.url})`,
                  }}
                ></div>
              </div>
            ))}
          </Slide>
        </div>
        <div className=" mt-4">
          <div className="  custom-card d-flex flex-column flex-md-row align-items-center">
            <div className="offer-details"></div>
            {/* <div className="code-badge mt-3 mt-md-0">Code: LUXUS15</div> */}
          </div>
          <div className=" mt-5">
            <h3>Shop Category</h3>
          </div>
          <div className="container mt-3">
            <div className="row">
              {subCategoriesList?.slice(0, 6)?.map((offer, index) => (
                <div
                  key={index}
                  className="col-6 col-md-4 col-lg-2 mb-3 text-center p-0"
                >
                  <div
                    className="offer-details"
                    onClick={() => handleSelectSubCategory(offer)}
                  >
                    <div className="image-container">
                      <img
                        src={offer.image}
                        className="rounded-circle img-fluid offer-image"
                        alt={offer.category_name}
                      />
                    </div>
                    <p className="font-weight-bold mt-2 subname">
                      {formatCapitalize(offer.category_name)}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="mt-5">
            <h3>Daily Deals</h3>
          </div>
          <div className="mt-3">
            <div className="row mx-0 mt-0">
              {visibleProducts?.map((card, index) => (
                <div className="col-md-3 mb-4" key={index}>
                  <Card sx={{ maxWidth: 345 }}>
                    <CardActionArea
                      onClick={() => navigate(`/product/${card.slug}`)}
                    >
                      <CardMedia
                        component="img"
                        sx={{ height: 200, objectFit: "contain", p: 2 }}
                        image={card.product_images[0]?.product_image || logo}
                        alt={card.name}
                      />
                      <CardContent>
                        <p className="titledescrip font-weight-bold mt-2">
                          {formatCapitalize(card.name)}
                        </p>
                        <Typography variant="body1" color="text.primary">
                          {doller.Aud} {card.product_prices.price}
                        </Typography>
                        {/* <Box display="flex" alignItems="center" mt={2}>
                          <Star color="primary" />
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            ml={1}
                          >
                            {card.rating} / 5
                          </Typography>
                        </Box> */}
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </div>
              ))}
              <p className="text-end fw-bold">
                <span
                  onClick={() => navigate("/dailydeals")}
                  style={{ cursor: "pointer" }}
                >
                  View All
                </span>
              </p>
            </div>
          </div>
          <div className=" mt-5">
            <div className="container-fluid custom-cards">
              <div className="custom-card-text">
                <h2>Growing your collection? We’ve got your back</h2>
                <p>You’re protected by the eBay Money Back Guarantee.</p>
                <button
                  className="custom-card-button"
                  onClick={() => navigate("/all-product")}
                >
                  Shop collectibles
                </button>
              </div>
              <div className="custom-card-images">
                <img
                  src="https://ir.ebaystatic.com/cr/v/c01/06_PopularDestination_PreLoved.jpg"
                  alt="Image 1"
                />
                <img
                  src="https://ir.ebaystatic.com/cr/v/c01/03_PopularDestination_Tire.jpg"
                  alt="Image 2"
                />
                <img
                  src="https://ir.ebaystatic.com/cr/v/c01/05_PopularDestination_Cards.jpg"
                  alt="Image 3"
                />
              </div>
            </div>
          </div>
          <div className=" mt-5">
            <h3>Popular Destinations</h3>
          </div>
          <div className="container mt-3">
            <div className="row">
              {ReverseOrder?.slice(0, 6)?.map((offer, index) => (
                <div
                  key={index}
                  className="col-6 col-md-4 col-lg-2 mb-3 text-center p-0"
                >
                  <div
                    className="offer-details"
                    onClick={() => handleSelectSubCategory(offer)}
                  >
                    <div className="image-container">
                      <img
                        src={offer.image}
                        className="rounded-circle img-fluid offer-image"
                        alt={offer.category_name}
                      />
                    </div>
                    <p className="font-weight-bold mt-2">
                      {formatCapitalize(offer.category_name)}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* <div className="mt-5">
            <h3>Recommended By Seller</h3>
          </div>
          <div className="mt-3">
            <div className="row mx-0 mt-0">
              {cardData.map((card, index) => (
                <div className="col-md-3 mb-4" key={index}>
                  <Card sx={{ maxWidth: 345 }}>
                    <CardActionArea>
                      <CardMedia
                        component="img"
                        sx={{ height: 200, objectFit: "contain", p: 2 }}
                        image={card.image}
                        alt={card.title}
                      />
                      <CardContent>
                        <p className="font-weight-bold mt-2">{card.title}</p>
                        <Typography variant="body1" color="text.primary">
                          {card.price}
                        </Typography>
                        <Box display="flex" alignItems="center" mt={2}>
                          <Star color="primary" />
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            ml={1}
                          >
                            {card.rating} / 5
                          </Typography>
                        </Box>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </div>
              ))}
            </div>
          </div> */}
          <div className=" mt-5">
            <div className="container-fluid custom-cardss">
              <div className="custom-card-text">
                <h2>Growing your collection? We’ve got your back</h2>
                <p>
                  Save on luxury jewelry, watches and handbags for your summer.
                </p>
              </div>
              <div className="custom-card-images">
                <img
                  src="https://ir.ebaystatic.com/cr/v/c01/06_PopularDestination_PreLoved.jpg"
                  alt="Image 1"
                />
                <img
                  src="https://ir.ebaystatic.com/cr/v/c01/03_PopularDestination_Tire.jpg"
                  alt="Image 2"
                />
                <img
                  src="https://ir.ebaystatic.com/cr/v/c01/05_PopularDestination_Cards.jpg"
                  alt="Image 3"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
const styles = {
  backdrop: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1000,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loader: {
    color: "white",
  },
};

export default Dashboard;
